import React from "react";

import '../styles/Home.css';

function Home() {
    return (
        <div className="background">
            <h1 className="welcomeText">Cartongesso Design <br/> <span>Specialisti nell'installazione del cartongesso</span></h1>
        </div>
    );
}

export default Home;