import './App.css'

import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import Section from './components/Section';
import CheckConnection from './components/CheckConnection';

import Home from './pages/Home';
import PhotoGallery from './pages/PhotoGallery';
import Services from './pages/Services';
import About from './pages/About';
import Contacts from './pages/Contacts';

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Image1 from './images/WhatsApp Image 2022-09-18 at 4.59.48 PM (1).jpeg'
import Image2 from './images/WhatsApp Image 2022-09-18 at 4.59.35 PM (2).jpeg'
import Image3 from './images/WhatsApp Image 2022-09-18 at 4.59.35 PM.jpeg'
import Image4 from './images/WhatsApp Image 2022-09-18 at 4.59.36 PM.jpeg'

class App extends Component {

  state = {
    section: [
      { id: 0, image: Image1, title: 'Il nostro lavoro', content: "La Cartongesso Design negl'anni si è specializzata nel pù ampio uso del cartongesso, indipendentemente se si tratti di lavori esterni e/o interni siamo in grado di portarlo a termine rispettando i criteri di serietà e professionalità che qualsiasi cliente desidererebbe." },
      { id: 1, image: Image2, title: "Anni d'esperienza", content: "Più di venti anni di esperienza messi a disposizione per il tuo progetto, mettendo in risalto l'abilità che nel tempo la Cartongesso Design è riuscita a padroneggiare, e mostrare con orgoglio cosa siamo in grado di fare attraverso la conoscenza appresa dopo anni e anni di esperienza sul campo." },
      { id: 2, image: Image3, title: 'Versatilità', content: "Negl'anni siamo diventati la scelta preferita di una vasta gamma di clientela, che sia per la tua impresa o per tua abitazione, la maestria della Cartongesso Design e le eccenzionali caratteristiche del cartongesso, conferiranno il miglior risultato che si vorrebbe ottenere." },
      { id: 3, image: Image4, title: 'Scelta del materiale', content: "L'attentenzione sulla scelta degli strumenti con cui lavoriamo sarà sempre messa in primo piano per garantire sicurezza, professionalità e maestria per quanto rigurda ogni aspetto del lavoro con cui abbiamo a che fare, anche nei più piccoli particolari." }
    ]
  }

  render() {
    return (
      <>
        <CheckConnection>
          <Router>
            <div className="App">
              <Navbar />
              <div className='content'>
                <Switch>
                  <Route exact path="/">
                    <Home />
                    <div className='content-section'>
                      {this.state.section.map(section => (
                        <Section
                          key={section.id}
                          section={section}
                        />
                      ))}
                    </div>
                  </Route>
                  <Route path="/photo-gallery">
                    <PhotoGallery />
                  </Route>
                  <Route path="/services">
                    <Services />
                  </Route>
                  <Route path="/chi-siamo">
                    <About />
                  </Route>
                  <Route path="/contatti">
                    <Contacts />
                  </Route>
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </CheckConnection>
      </>
    );
  }
}

export default App;
