import React, { Component } from "react";

import '../styles/Services.css'

import Article from '../components/Article';

import Image1 from '../images/Pianta3D.jpg';
import Image2 from '../images/LastraElevata.jpg';
import Image3 from '../images/Municipio-Caldarola-Banner-home-scaled.jpg';

class Services extends Component {

    state = {
        article: [
            {
                id: 0, image: Image1, title: 'I nostri servizi più richiesti', text: <ul>
                    <li>Planimetria, viste 3D, Rendering con diversa disposizione degli ambienti interni;</li>
                    <li>Progetti degli impianti elettrici e domotici, idrici, riscaldamento, codizionamento;</li>
                    <li>Progettazioni reti esterne e sotto servizi;</li>
                    <li>Progettazione impianti antintrusione, trasmissione dati e impianti antincendio;</li>
                    <li>Progetto di arredo con indicazione del modello di ogni singolo arredo;</li>
                    <li>Particolari costruttivi, schede tecniche illustrative dei materiali di finitura;</li>
                    <li>Relazioni techiche, collaudi e certificazioni energetiche;</li>
                    <li>Computi metrici ed estimativi, capitolati di appalto.</li>
                </ul>
            },
            {
                id: 1, image: Image2, title: 'Prodotti', text: <p>I sistemi a secco comprendono una vasta gamma di prodotti (lastre in cartongesso, pannelli per controsoffiti, strutture metalliche ed accessori) che, combinati tra loro, permettono la realizzazione di pareti divisorie, controsoffiti sia ispezionabili sia ad aspetto tradizionale e contropareti. <br /> Oggi il cartongesso ha assunto una valenza importantissima nell'edilizia, sopratutto nell'ingresso delle nuove normative Europee in fatto di coibentazione termica ed aucustica. Grazie alle sua versatilità, alle numerossime applicazioni ed innovazioni oggi non si parla di cartongesso ma di sistemi a secco. Dotati di caratteristiche superiori di isolamento e sicurezza, ideali per una facile istallazione degli impianti, leggere e resistenti, facilmente conformabili, sono oggi il sistema che permette di realizzare nel minor tempo e con maggior pulizia qualsiasi soluzione funzionale o estetica. <br /> Tali sistemi sono utilizzati per la protezione del fuoco, cappotti per esterni, coibentazione, applicazione estetiche, compartimentazione, problematiche di umidità e muffa, opere architettoniche da esterno mediante fibrocemento ecc. </p>
            },
            {
                id: 2, image: Image3, title: 'Sistema di Protezione Termica Integrale', text: 
                <ul>
                    <li>Realizzare l'isolamento di un edificio con Sistema di Protezione Termica Integrale è la soluzione più valida:</li>
                    <li>Protegge tutta la costruzione dalle estrursioni termiche e dagli agenti atmosferici, prolungandone la durata nel tempo e riducendone i costi di manutezione.</li>
                    <li>Permettono di realizzare l'solamento in continuo su tutte le superfici disperdenti eliminando i ponti termici.</li>
                    <li>Consente di sfruttare pienamente l'inerzia termica della struttura migliorandone il rendimento, il confort e le condizione igeniche dell'abitato.</li>
                    <li>Annulla tutte le possibilità di formazione della condensa.</li>
                    <li>Contribuisce al risparmio energetico e riduce l'inquinamento atmosferico.</li>
                    <li>Valorizza l'immobile.</li>
                    <li>Sistemi isolamento perfetto per nuove costruzioni ma risulta ideali anche per le ristruttuazioni perchè consente di intevenire senza sconvolgere l'abitabilità degli occupanti.</li>
                    <li>Non riduce la superficie abitabile e ha un rapporto costo benifici ottimile</li>
                    <li>Non pone limite alla progettazione estetica delle facciate.</li>
                </ul>
            },
        ]
    }

    render() {
        return (
            <>
                {this.state.article.map(article => (
                    <Article
                        key={article.id}
                        title={article.title}
                        image={article.image}
                        text={article.text}
                    />
                ))}
            </>
        )
    }
}

export default Services;