import React, { Component } from "react";

import '../styles/Section.css'

class Section extends Component {
    render() {
        return (
            <div className="card">
                <div className="img-box">
                    <img src={this.props.section.image} />
                </div>
                <div className="content-card">
                    <h2>{this.props.section.title}</h2>
                    <p>{this.props.section.content}</p>
                </div>
            </div>
        );
    };
}

export default Section;