import React, { Component } from "react";

class Photo extends Component {
    render() {
        return (
            <div onClick={() => this.props.onViewer(this.props.photos)} className="image">
                <img src={this.props.photos.image} alt="errore"/>
            </div>
        )
    }
}

export default Photo;