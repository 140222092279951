import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import '../styles/Contacts.css'

function Contacts() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.
            sendForm(
                'service_79u8k8m',
                'template_9u9gilg',
                form.current,
                'OlB_z_Py5ZUPpw16K'
            )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (



        <div className='container-contacts'>

            <br />

            <div className='contacts'>
                <div className='section-container'>
                    <div className='section'>
                        <i className="fa-solid fa-phone fa-2xl"></i>
                        <p>+39 3208868080</p>
                    </div>

                    <div className='section'>
                        <i className="fa-solid fa-location-dot fa-2xl"></i>
                        <p>Mendicino, Cosenza</p>
                    </div>

                    <div className='section'>
                        <i className="fa-solid fa-envelope fa-2xl"></i>
                        <p>Gianlucaarreda@hotmail.com</p>
                    </div>
                </div>

                <div className='container-form'>
                    <h1>Contattaci!</h1>
                    <br />
                    <form className='form' ref={form} onSubmit={sendEmail}>
                        <label>Nome</label><br />
                        <input type="text" name="user_name" /><br />
                        <label>Email</label><br />
                        <input type="email" name="user_email" /><br />
                        <label>Messaggio</label><br />
                        <textarea name="message" /><br />
                        <input className='submit-button' type="submit" value="Invia" />
                    </form>
                </div>
            </div>

            <br />

        </div>

    )
}

export default Contacts;