import React from "react";
import { Detector } from "react-detect-offline";

import GifNoConnection from '../images/icons8-wi-fi (1).gif'

const CheckConnection = props => {
    return (
        <>
            <Detector
                render={({ online }) => (
                    online ? props.children :
                        <div style={{
                            paddingTop: '10px', 
                            textAlign: 'center', 
                            top: '50%',
                            left: '50%', 
                            position: 'absolute', 
                            transform: 'translateX(-50%) translateY(-50%)'
                        }}>
                            <h2>Cartongesso Design</h2>
                            <img src={GifNoConnection} />
                            <h1 style={{ marginBottom: '5px' }}>Nessuna connessione!</h1>
                            <h4 style={{ margin: '0', marginBottom: '50px' }}>Per favore controlla la tua connessione.</h4>
                        </div>
                )}
            />
        </>
    )
}

export default CheckConnection;