import React, { Component } from "react";

import '../styles/Services.css'

class Article extends Component {
    render() {
        return (
            <div className="container-article">
                <div className="article">
                    <div className="article-img">
                        <img src={this.props.image} />
                    </div>
                    <br />
                    <div className="article-content">
                        <h1>{this.props.title}</h1><br />
                        <p>{this.props.text}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Article;