import React, { Component } from "react";

import '../styles/PhotoGallery.css'

import Photo from '../components/Photo';

import Image1 from '../images/WhatsApp Image 2022-09-18 at 4.59.35 PM (1).jpeg'
import Image2 from '../images/WhatsApp Image 2022-09-18 at 4.59.35 PM (2).jpeg'
import Image3 from '../images/WhatsApp Image 2022-09-18 at 4.59.35 PM.jpeg'
import Image4 from '../images/WhatsApp Image 2022-09-18 at 4.59.36 PM.jpeg'
import Image5 from '../images/WhatsApp Image 2022-09-18 at 4.59.37 PM.jpeg'
import Image6 from '../images/WhatsApp Image 2022-09-18 at 4.59.38 PM.jpeg'
import Image7 from '../images/WhatsApp Image 2022-09-18 at 4.59.40 PM.jpeg'
import Image8 from '../images/WhatsApp Image 2022-09-18 at 4.59.41 PM.jpeg'
import Image9 from '../images/WhatsApp Image 2022-09-18 at 4.59.43 PM (1).jpeg'
import Image10 from '../images/WhatsApp Image 2022-09-18 at 4.59.43 PM (2).jpeg'
import Image11 from '../images/WhatsApp Image 2022-09-18 at 4.59.43 PM.jpeg'
import Image12 from '../images/WhatsApp Image 2022-09-18 at 4.59.45 PM.jpeg'
import Image13 from '../images/WhatsApp Image 2022-09-18 at 4.59.46 PM.jpeg'
import Image14 from '../images/WhatsApp Image 2022-09-18 at 4.59.48 PM (1).jpeg'
import Image15 from '../images/WhatsApp Image 2022-09-18 at 4.59.48 PM (2).jpeg'
import Image16 from '../images/WhatsApp Image 2022-09-18 at 4.59.48 PM.jpeg'
import Image17 from '../images/WhatsApp Image 2022-09-18 at 4.59.49 PM (1).jpeg'
import Image18 from '../images/WhatsApp Image 2022-09-18 at 4.59.49 PM (2).jpeg'
import Image19 from '../images/WhatsApp Image 2022-09-18 at 4.59.49 PM (3).jpeg'
import Image20 from '../images/WhatsApp Image 2022-09-18 at 4.59.49 PM (4).jpeg'
import Image21 from '../images/WhatsApp Image 2022-09-18 at 4.59.49 PM.jpeg'
import Image22 from '../images/WhatsApp Image 2022-09-18 at 4.59.50 PM.jpeg'
import Image23 from '../images/WhatsApp Image 2022-09-18 at 5.02.35 PM (1).jpeg'
import Image24 from '../images/WhatsApp Image 2022-09-18 at 5.02.35 PM.jpeg'
import Image25 from '../images/WhatsApp Image 2022-09-18 at 5.02.37 PM.jpeg'
import Image26 from '../images/WhatsApp Image 2022-09-18 at 5.02.38 PM.jpeg'
import Image27 from '../images/WhatsApp Image 2022-09-18 at 5.02.40 PM.jpeg'
import Image28 from '../images/WhatsApp Image 2022-09-18 at 5.02.42 PM.jpeg'
import Image29 from '../images/WhatsApp Image 2022-09-18 at 5.02.43 PM.jpeg'
import Image30 from '../images/WhatsApp Image 2022-09-18 at 5.02.45 PM (1).jpeg'
import Image31 from '../images/WhatsApp Image 2022-09-18 at 5.02.45 PM (2).jpeg'
import Image32 from '../images/WhatsApp Image 2022-09-18 at 5.02.45 PM.jpeg'
import Image33 from '../images/WhatsApp Image 2022-09-18 at 5.02.46 PM (1).jpeg'
import Image34 from '../images/WhatsApp Image 2022-09-18 at 5.02.46 PM (2).jpeg'
import Image35 from '../images/WhatsApp Image 2022-09-18 at 5.02.46 PM (3).jpeg'
import Image36 from '../images/WhatsApp Image 2022-09-18 at 5.02.46 PM (4).jpeg'
import Image37 from '../images/WhatsApp Image 2022-09-18 at 5.02.46 PM.jpeg'
import Image38 from '../images/WhatsApp Image 2022-09-18 at 5.02.47 PM (1).jpeg'
import Image39 from '../images/WhatsApp Image 2022-09-18 at 5.02.47 PM.jpeg'
import Image40 from '../images/WhatsApp Image 2022-09-18 at 5.05.36 PM (1).jpeg'
import Image41 from '../images/WhatsApp Image 2022-09-18 at 5.05.36 PM.jpeg'
import Image42 from '../images/WhatsApp Image 2022-09-18 at 5.05.37 PM (1).jpeg'
import Image43 from '../images/WhatsApp Image 2022-09-18 at 5.05.37 PM (2).jpeg'
import Image44 from '../images/WhatsApp Image 2022-09-18 at 5.05.37 PM (3).jpeg'
import Image45 from '../images/WhatsApp Image 2022-09-18 at 5.05.37 PM (4).jpeg'
import Image46 from '../images/WhatsApp Image 2022-09-18 at 5.05.37 PM.jpeg'
import Image47 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM (1).jpeg'
import Image48 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM (2).jpeg'
import Image49 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM (3).jpeg'
import Image50 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM (4).jpeg'
import Image51 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM (5).jpeg'
import Image52 from '../images/WhatsApp Image 2022-09-18 at 5.05.38 PM.jpeg'
import Image53 from '../images/WhatsApp Image 2022-09-18 at 5.09.20 PM (1).jpeg'
import Image54 from '../images/WhatsApp Image 2022-09-18 at 5.09.20 PM (2).jpeg'
import Image55 from '../images/WhatsApp Image 2022-09-18 at 5.09.20 PM (3).jpeg'
import Image56 from '../images/WhatsApp Image 2022-09-18 at 5.09.20 PM (4).jpeg'
import Image57 from '../images/WhatsApp Image 2022-09-18 at 5.09.20 PM.jpeg'
import Image58 from '../images/WhatsApp Image 2022-09-18 at 5.09.21 PM (1).jpeg'
import Image59 from '../images/WhatsApp Image 2022-09-18 at 5.09.21 PM (2).jpeg'
import Image60 from '../images/WhatsApp Image 2022-09-18 at 5.09.21 PM (3).jpeg'
import Image61 from '../images/WhatsApp Image 2022-09-18 at 5.09.21 PM.jpeg'
import Image62 from '../images/WhatsApp Image 2022-09-18 at 5.09.22 PM (1).jpeg'
import Image63 from '../images/WhatsApp Image 2022-09-18 at 5.09.22 PM (2).jpeg'
import Image64 from '../images/WhatsApp Image 2022-09-18 at 5.09.22 PM (3).jpeg'
import Image65 from '../images/WhatsApp Image 2022-09-18 at 5.09.22 PM (4).jpeg'
import Image66 from '../images/WhatsApp Image 2022-09-18 at 5.09.22 PM.jpeg'
import Image67 from '../images/WhatsApp Image 2022-09-18 at 5.09.23 PM.jpeg'
import Image68 from '../images/WhatsApp Image 2022-09-18 at 5.13.37 PM (1).jpeg'
import Image69 from '../images/WhatsApp Image 2022-09-18 at 5.13.37 PM (2).jpeg'
import Image70 from '../images/WhatsApp Image 2022-09-18 at 5.13.37 PM (3).jpeg'
import Image71 from '../images/WhatsApp Image 2022-09-18 at 5.13.37 PM.jpeg'
import Image72 from '../images/WhatsApp Image 2022-09-18 at 5.13.38 PM (1).jpeg'
import Image73 from '../images/WhatsApp Image 2022-09-18 at 5.13.38 PM (2).jpeg'
import Image74 from '../images/WhatsApp Image 2022-09-18 at 5.13.38 PM (3).jpeg'
import Image75 from '../images/WhatsApp Image 2022-09-18 at 5.13.38 PM.jpeg'
import Image76 from '../images/WhatsApp Image 2022-09-18 at 5.13.39 PM (1).jpeg'
import Image77 from '../images/WhatsApp Image 2022-09-18 at 5.13.39 PM (2).jpeg'
import Image78 from '../images/WhatsApp Image 2022-09-18 at 5.13.39 PM.jpeg'
import Image79 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM (1).jpeg'
import Image80 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM (2).jpeg'
import Image81 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM (3).jpeg'
import Image82 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM (4).jpeg'
import Image83 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM (5).jpeg'
import Image84 from '../images/WhatsApp Image 2022-09-18 at 5.13.40 PM.jpeg'
import Image85 from '../images/WhatsApp Image 2022-09-18 at 5.13.41 PM (1).jpeg'
import Image86 from '../images/WhatsApp Image 2022-09-18 at 5.13.41 PM (2).jpeg'
import Image87 from '../images/WhatsApp Image 2022-09-18 at 5.13.41 PM (3).jpeg'
import Image88 from '../images/WhatsApp Image 2022-09-18 at 5.13.41 PM (4).jpeg'
import Image89 from '../images/WhatsApp Image 2022-09-18 at 5.13.41 PM.jpeg'
import Image90 from '../images/WhatsApp Image 2022-09-18 at 5.13.42 PM (1).jpeg'
import Image91 from '../images/WhatsApp Image 2022-09-18 at 5.13.42 PM (2).jpeg'
import Image92 from '../images/WhatsApp Image 2022-09-18 at 5.13.42 PM (3).jpeg'
import Image93 from '../images/WhatsApp Image 2022-09-18 at 5.13.42 PM (4).jpeg'
import Image94 from '../images/WhatsApp Image 2022-09-18 at 5.13.42 PM.jpeg'
import Image95 from '../images/WhatsApp Image 2022-09-18 at 5.19.07 PM (1).jpeg'
import Image96 from '../images/WhatsApp Image 2022-09-18 at 5.19.07 PM (2).jpeg'
import Image97 from '../images/WhatsApp Image 2022-09-18 at 5.19.07 PM (3).jpeg'
import Image98 from '../images/WhatsApp Image 2022-09-18 at 5.19.07 PM (4).jpeg'
import Image99 from '../images/WhatsApp Image 2022-09-18 at 5.19.07 PM.jpeg'
import Image100 from '../images/WhatsApp Image 2022-09-18 at 5.19.08 PM (1).jpeg'
import Image101 from '../images/WhatsApp Image 2022-09-18 at 5.19.08 PM (2).jpeg'
import Image102 from '../images/WhatsApp Image 2022-09-18 at 5.19.08 PM.jpeg'

var ImagePhotoViewer = Image1;

class PhotoGallery extends Component {
    state = {
        photos: [
            { id: 0, image: Image1 },
            { id: 1, image: Image2 },
            { id: 2, image: Image3 },
            { id: 3, image: Image4 },
            { id: 4, image: Image5 },
            { id: 5, image: Image6 },
            { id: 6, image: Image7 },
            { id: 7, image: Image8 },
            { id: 8, image: Image9 },
            { id: 9, image: Image10 },
            { id: 10, image: Image11 },
            { id: 11, image: Image12 },
            { id: 12, image: Image13 },
            { id: 13, image: Image14 },
            { id: 14, image: Image15 },
            { id: 15, image: Image16 },
            { id: 16, image: Image17 },
            { id: 17, image: Image18 },
            { id: 18, image: Image19 },
            { id: 19, image: Image20 },
            { id: 20, image: Image21 },
            { id: 21, image: Image22 },
            { id: 22, image: Image23 },
            { id: 23, image: Image24 },
            { id: 24, image: Image25 },
            { id: 25, image: Image26 },
            { id: 26, image: Image27 },
            { id: 27, image: Image28 },
            { id: 28, image: Image29 },
            { id: 29, image: Image30 },
            { id: 30, image: Image31 },
            { id: 31, image: Image32 },
            { id: 32, image: Image33 },
            { id: 33, image: Image34 },
            { id: 34, image: Image35 },
            { id: 35, image: Image36 },
            { id: 36, image: Image37 },
            { id: 37, image: Image38 },
            { id: 38, image: Image39 },
            { id: 39, image: Image40 },
            { id: 40, image: Image41 },
            { id: 41, image: Image42 },
            { id: 42, image: Image43 },
            { id: 43, image: Image44 },
            { id: 44, image: Image45 },
            { id: 45, image: Image46 },
            { id: 46, image: Image47 },
            { id: 47, image: Image48 },
            { id: 48, image: Image49 },
            { id: 49, image: Image50 },
            { id: 50, image: Image51 },
            { id: 51, image: Image52 },
            { id: 52, image: Image53 },
            { id: 53, image: Image54 },
            { id: 54, image: Image55 },
            { id: 55, image: Image56 },
            { id: 56, image: Image57 },
            { id: 57, image: Image58 },
            { id: 58, image: Image59 },
            { id: 59, image: Image60 },
            { id: 60, image: Image61 },
            { id: 61, image: Image62 },
            { id: 62, image: Image63 },
            { id: 63, image: Image64 },
            { id: 64, image: Image65 },
            { id: 65, image: Image66 },
            { id: 66, image: Image67 },
            { id: 67, image: Image68 },
            { id: 68, image: Image69 },
            { id: 69, image: Image70 },
            { id: 70, image: Image71 },
            { id: 71, image: Image72 },
            { id: 72, image: Image73 },
            { id: 73, image: Image74 },
            { id: 74, image: Image75 },
            { id: 75, image: Image76 },
            { id: 76, image: Image77 },
            { id: 77, image: Image78 },
            { id: 78, image: Image79 },
            { id: 79, image: Image80 },
            { id: 80, image: Image81 },
            { id: 81, image: Image82 },
            { id: 82, image: Image83 },
            { id: 83, image: Image84 },
            { id: 84, image: Image85 },
            { id: 85, image: Image86 },
            { id: 86, image: Image87 },
            { id: 87, image: Image88 },
            { id: 88, image: Image89 },
            { id: 89, image: Image90 },
            { id: 90, image: Image91 },
            { id: 91, image: Image92 },
            { id: 92, image: Image93 },
            { id: 93, image: Image94 },
            { id: 94, image: Image95 },
            { id: 95, image: Image96 },
            { id: 96, image: Image97 },
            { id: 97, image: Image98 },
            { id: 98, image: Image99 },
            { id: 99, image: Image100 },
            { id: 100, image: Image101 },
            { id: 101, image: Image102 }
        ]
    }



    handleViewer = photo => {
        const photos = [...this.state.photos];
        const id = photos.indexOf(photo);
        photos[id] = { ...photo };

        document.querySelector(':root').style.setProperty('--photo-viewer', 'block')

        ImagePhotoViewer = photos[id].image;
        this.setState({ photos });
    }

    handleRemove = () => {
        document.querySelector(':root').style.setProperty('--photo-viewer', 'none')
    }

    render() {
        return (
            <div className="container">
                <h1>Photo Gallery</h1>
                <div className="image-container">
                    {this.state.photos.map(photos => (
                        <Photo
                            key={photos.id}
                            onViewer={this.handleViewer}
                            photos={photos}
                        />
                    ))}
                </div>

                <div className="popup-image">
                    <span onClick={this.handleRemove}>&times;</span>
                    <img src={ImagePhotoViewer} alt="errore" />
                </div>
            </div>
        );
    }
}




export default PhotoGallery;