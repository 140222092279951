import React from "react";

import '../styles/Footer.css'

function Footer() {
    return (
        <footer>
            <div className="social">
                <a href="#"><i className="fab fa-instagram"></i></a>
                <a href="#"><i className="fab fa-snapchat"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-facebook-f"></i></a>
            </div>

            <ul className="footer-ul">
                <li><a href="/">Home</a></li>
                <li><a href="/services">Servizi</a></li>
                <li><a href="/chi-siamo">Chi siamo</a></li>
                <li><a href="/photo-gallery">Photo Gallery</a></li>
                <li><a href="/contatti">Contatti</a></li>
            </ul>

            <p className="copyright">
                Cartongesso Design @ 2022-2023<br />
                <br />
                Developed by Ernesto Samuele Rovito<br />
                per contatti : samuelerovito002@gmail.com
            </p>
        </footer>
    );
}

export default Footer;